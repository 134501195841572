import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BackgroundImage from 'gatsby-background-image'
import GoogleMapReact from "google-map-react"
import ContactInfo from "../../content/settings/contact_info.yml"
import { Fade } from "react-awesome-reveal";

const ContactUsPage = ({ data }) => {

  const title = data.markdownRemark.frontmatter.title;
  const bannerTitle = data.markdownRemark.frontmatter.banner.small_title;
  const bannerCaption = data.markdownRemark.frontmatter.banner.caption;
  const bannerImage = data.markdownRemark.frontmatter.banner.banner_image.childImageSharp.fluid;
  const formTitle = data.markdownRemark.frontmatter.form.title;
  const formCaption = data.markdownRemark.frontmatter.form.caption;

  const MapMarker = () => {
    return (
      <svg className="map-marker" xmlns="http://www.w3.org/2000/svg" width="76" height="100" viewBox="0 0 76 100">
        <path d="M38,0C19.43,0,.22,14.13.22,37.78.22,62.86,35,97.87,36.43,99.35a2.22,2.22,0,0,0,3.14,0h0c1.48-1.48,36.21-36.49,36.21-61.57C75.78,14.13,56.57,0,38,0Z"/>
        <path className="detail" d="M29.31,32.1c-.88.5-1.7,1-2.56,1.44-1.42.71-1.44.7-2.15-.69s-1.49-2.94-2.24-4.4c-.18-.34-.12-.47.22-.68,1.63-1,3.28-2,4.83-3.07A15.24,15.24,0,0,1,33.85,22a.75.75,0,0,1,.6.25A4.15,4.15,0,0,0,38,23.9a4.11,4.11,0,0,0,3.11-1.6,1,1,0,0,1,1-.37,13.13,13.13,0,0,1,5.72,2.16,22.31,22.31,0,0,0,5.35,2.27,3,3,0,0,1,.42.14.85.85,0,0,1,.19.14c-.59,1.64-1.17,3.28-1.76,4.91-.18.52-.36,1-.53,1.55-.11.35-.31.55-.69.42l-3.11-1.09c-.16,0-.32-.13-.53-.22a1.35,1.35,0,0,0,0,.28,39.74,39.74,0,0,1-.42,8.58,16,16,0,0,1-3.44,8,7.76,7.76,0,0,1-.86.95.56.56,0,0,1-.53.08c-2.69-2-5.74-2.36-9-2a23.13,23.13,0,0,1-4.48.08,10.4,10.4,0,0,1-5.19-2.27c-.33-.24-.13-.44,0-.65,1.09-1.45,2.19-2.89,3.27-4.35a13.89,13.89,0,0,0,2.33-5.66c.18-.9.33-1.81.49-2.72A1.45,1.45,0,0,0,29.31,32.1Z"/>
      </svg>
    )
  }

  return (
    <Layout>
      <SEO title={title} />
      <section className="banner contact-us-banner">
        <BackgroundImage Tag="section" className="banner-image" fluid={bannerImage} backgroundColor={`#000`}>
          <div className="banner-overlay-horizontal" />
          <div className="banner-overlay-vertical" />
          <div className="banner-content">
            <div className="container">
              <div className="row">
                <div className="col">
                  <Fade triggerOnce>
                    <h1>{bannerTitle}</h1>
                    <p>{bannerCaption}</p>
                  </Fade>
                </div>
                <div className="col">
                  <Fade triggerOnce>
                    <div className="google-maps-wrapper">
                      <GoogleMapReact
                        bootstrapURLKeys={{key:'AIzaSyB8ZGGcOqPPo4H39zirIweFjQposBC3Nq4'}}
                        defaultCenter={{lat:-45.880340,lng:170.499874}}
                        defaultZoom={15}
                        options={{zoomControl:false,fullscreenControl:false}}
                      >
                        <MapMarker
                          lat={-45.881140}
                          lng={170.499874}
                          text="Our Factory"
                        />
                      </GoogleMapReact>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </BackgroundImage>
      </section>
      <section>
        <div className="row no-gutters">
          <div className="col col-two-fifths">
            <div className="contact-content-wrapper">
              <Fade triggerOnce>
                <h3>Phone</h3>
                <p><a href="tel:034778250">{ContactInfo.phone}</a></p>
                <h3>Email</h3>
                <p><a href="mailto:simon@monogramhouse.co.nz">{ContactInfo.email}</a></p>
                <h3>Social</h3>
                <p><a href="facebook.com/monogramhouse">Facebook</a></p>
                <h3>Address</h3>
                <ul>
                  <li>{ContactInfo.address.street_address}</li>
                  <li>{ContactInfo.address.city}</li>
                  <li>{ContactInfo.address.post_code}</li>
                </ul>
              </Fade>
            </div>
          </div>
          <div className="col col-three-fifths">
            <div className="contact-form-wrapper">
              <Fade triggerOnce>
                <div className="media">
                  <div className="icon-wrapper">
                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
                      <g strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fill="#ffac00" stroke="#ffac00">
                        <path fill="none" strokeMiterlimit="10" d=" M21.164,38.997C23.693,40.262,26.73,41,30,41c1.179,0,2.326-0.102,3.432-0.284L42,45v-8.081c2.482-2.114,4-4.881,4-7.919 c0-1.038-0.176-2.042-0.506-2.999"></path>
                        <path fill="none" stroke="#ffac00" strokeMiterlimit="10" d="M22,2 C10.954,2,2,9.163,2,18c0,3.594,1.499,6.9,4,9.571V38l9.665-4.833C17.658,33.7,19.783,34,22,34c11.046,0,20-7.163,20-16 S33.046,2,22,2z"></path>
                      </g>
                    </svg>
                  </div>
                  <div className="media-body">
                    <h3>{formTitle}</h3>
                    <p>{formCaption}</p>
                  </div>
                </div>
              </Fade>
              <Fade triggerOnce>
                <form method="post" netlify-honeypot="bot-field" data-netlify="true" name="contact">
                  <input type="hidden" aria-label="Bot Field" name="bot-field" />
                  <input type="hidden" aria-label="Form Name" name="form-name" value="contact" />
                  <div className="row">
                    <div className="col">
                      <div className="form-group">
                        <label htmlFor="name">Name
                          <input type="text" name="name" required id="name" aria-label="Name" />
                        </label>
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-group">
                        <label htmlFor="email">Email
                          <input type="email" name="email" required id="email" aria-label="Email" />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="subject">Subject
                      <input type="text" name="subject" id="subject" aria-label="Subject" />
                    </label>
                  </div>
                  <div className="form-group">
                    <label htmlFor="message">Message
                      <textarea name="message" required id="message" aria-label="Message" rows="3" />
                    </label>
                  </div>
                  <div className="form-group actions">
                    <button className="button" type="submit" aria-label="Submit Form">Send</button>
                    <input type="reset" aria-label="Clear Form" value="Clear" />
                  </div>
                </form>
              </Fade>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
query {
  markdownRemark(frontmatter: { template_key: { eq: "contact-us" } }) {
    frontmatter {
      title
      banner {
        small_title
        caption
        banner_image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      form {
        title
        caption
      }
    }
  }
}
`

export default ContactUsPage
